.horizontal-slider{
    background-color: black;
}

.MuiSlider-root{
    margin-bottom: 0px !important;
}

.MuiSlider-markLabel{
    font-size: 0px !important;
    top: 0px!important;
}
.css-xmer36-MuiSlider-root{
    color:#739ab5 !important
}

.css-xmer36-MuiSlider-root{
    margin-bottom: -.8vw !important;
}

.Editor-module_editor__o0Gjz {
    min-width: 0 !important;
    max-height: 40% !important;
}

.App-module_top__SBeSV {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: 0px;
}

.App-module_top__SBeSV::-webkit-scrollbar {
    background-color: gray;
    height: 0px;
    width: 0px;
    
}

.ketcher-container{
    height: 400px !important;
    max-height: 400px !important;
}