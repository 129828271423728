.title-text {
    font-size: 19px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
}

.tranche-table {
    max-height: 74vh;
    overflow: scroll;
   

}


.top-bar {

    border: 1px solid #ccc;
    
}

.top-button {
    padding-right: 5px;
}

table {
    position: relative;
    border-collapse: collapse;
}

.left-bar{
    background-color: white;
   
    color: black;
    border: 1px solid #ccc; 
    
    font-weight: bold;
}


.div-last {
    width: max-content;
    display: table;
    margin: 0 auto;

}

.table>:not(caption)>*>*{
    padding: .5em .5em !important;
}

p {
    margin-bottom: 0px;
}

th {
    
    
    text-align: center;
    width: max-content;
    background-color: white !important;
}

td {
    border: 1px solid #ccc;
    text-align: center !important;
    
    
}
/* 
thead th:first-child {
    left: 0;
    z-index: 2;
    border: none;
} */

thead th:last-child {
    right: 0;
    z-index: 2;
}

thead th:first-child {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
  
    background-color: #ccc;
    color: black;
    
    top: 0;
    left: 0;
    z-index: 2;
  
}


thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    
    background-color: white !important;
    color: black;
  
    box-shadow: inset 0 0 0 0.2px black !important;
    top: 0;
    z-index: 1;
}

tbody th {
    position: -webkit-sticky;
    box-shadow: inset 0 0 0 0.2px black !important;
    /* for Safari */
    position: sticky;
    left: 0;
    background: white;
    color: black;
    border: 1px solid #ccc;

    z-index: 1;

}


tbody th:last-child {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    right: 0;
    background-color: white;
    color: black;
    text-align: center;
  
    font-weight: bold;

}

tfoot th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    bottom: 0;
    background: white;
    color: black;

    box-shadow: 0 0 0 .2px black inset !important;
}

tfoot th:first-child {
    left: 0;
    z-index: 2;

}

tfoot th:last-child {
    right: 0;
    z-index: 2;
}

.cell-th:hover {
    background-color: grey;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: grey;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ccc;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.tranche-loading {
    
    min-height: 35rem;
}